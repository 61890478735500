import { ZonePermissionApiModel } from '../models/permissions/zonePermissionApiModel';
import { Injectable } from '@angular/core';
import { ClientConfiguration } from 'src/app/configuration/models/client-configuration.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigurationService } from 'src/app/configuration/services/configuration.service';
import { Observable } from 'rxjs';
import { PermissionRequiringEntity } from '../models/permissionRequiringEntity';
import { EnumApiModel } from '../models/enumApiModel';
import { PermissionType } from '../models/permissionType';
import { OrganizationPermissionApiModel } from '../models/organizationPermissionApiModel';
import { UserGroupPermissionsApiModel } from '../models/permissions/userGroupPermissionsApiModel';

@Injectable()
export class PermissionService {
    private readonly configuration: ClientConfiguration;

    constructor(private readonly httpClient: HttpClient, configurationService: ConfigurationService) {
        this.configuration = configurationService.configuration;
    }

    hasPermissionForAnySite(organizationId: number): Observable<boolean> {
        const url = `${this.configuration.organizationsApiBaseUrl}/permissions/HasUserPermissionForAnySite/${organizationId}`;
        return this.httpClient.get<boolean>(url);
    }

    hasPermissionTypeForAnySite(organizationId: number,permissionType: PermissionType): Observable<boolean> {
      const url = `${this.configuration.organizationsApiBaseUrl}/permissions/HasUserPermissionTypeForAnySite/${organizationId}/${permissionType}`;
      return this.httpClient.get<boolean>(url);
  }

    hasUserAnyPermissionForEntity(entityType: PermissionRequiringEntity, entityId: number, permissionTypes: PermissionType[]): Observable<boolean> {
        const permissionParams = {} as any;
        permissionParams.entityType = entityType;
        permissionParams.entityId = entityId;
        permissionParams.permissionTypes = permissionTypes;
        let encodedQuery = encodeURI(JSON.stringify(permissionParams));
        encodedQuery = encodedQuery.replace(/\+/g, '%2B');
        const url = `${this.configuration.organizationsApiBaseUrl}/permissions/HasUserAnyPermissionForEntity?permissionParams=${encodedQuery}`;
        return this.httpClient.get<boolean>(url);
    }

    hasPermissionForEntity(entityType: PermissionRequiringEntity, entityId: number, permissionType: PermissionType): Observable<boolean> {
        const url = `${this.configuration.organizationsApiBaseUrl}/permissions/HasUserPermissionForEntity/${entityType}/${entityId}/${permissionType}`;
        return this.httpClient.get<boolean>(url);
    }

    getUserGroupPermissions(userGroupId: number): Observable<UserGroupPermissionsApiModel> {
      const url = `${this.configuration.organizationsApiBaseUrl}/permissions/GetUserGroupPermissions/${userGroupId}`;
      return this.httpClient.get<UserGroupPermissionsApiModel>(url);
    }

    getPermissionLevels(): Observable<EnumApiModel[]> {
        const url = `${this.configuration.organizationsApiBaseUrl}/permissions/GetPermissionLevels`;
        return this.httpClient.get<EnumApiModel[]>(url);
    }
    
    getPermissionLevelsMobileApp(): Observable<EnumApiModel[]> {
        const url = `${this.configuration.organizationsApiBaseUrl}/permissions/GetPermissionLevelsMobileApp`;
        return this.httpClient.get<EnumApiModel[]>(url);
    }

    getPermissionTypes(): Observable<EnumApiModel[]> {
        const url = `${this.configuration.organizationsApiBaseUrl}/permissions/GetPermissionTypes`;
        return this.httpClient.get<EnumApiModel[]>(url);
    }
    
    getPermissionTypesMobileApp(): Observable<EnumApiModel[]> {
        const url = `${this.configuration.organizationsApiBaseUrl}/permissions/GetPermissionTypesMobileApp`;
        return this.httpClient.get<EnumApiModel[]>(url);
    }

    getPermissionInformationMobileApp(): Observable<EnumApiModel[]> {
        const url = `${this.configuration.organizationsApiBaseUrl}/permissions/GetPermissionInformationMobileApp`;
        return this.httpClient.get<EnumApiModel[]>(url);
    }
    
    updateUserGroupZonePermissions(permissions: ZonePermissionApiModel[]) {
      const url = `${this.configuration.organizationsApiBaseUrl}/permissions/UpdateUserGroupsZonePermissions`;
      return this.httpClient.put(url, permissions, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) });
    }

    updateUserGroupMobileAppPermissions(permissions: OrganizationPermissionApiModel[]) {
        const url = `${this.configuration.organizationsApiBaseUrl}/permissions/UpdateUserGroupsMobileAppPermissions`;
        return this.httpClient.put(url, permissions, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) });
      }


    hasPermissionForSite(permissionType: PermissionType, siteId: number): Observable<boolean> {
        const url = `${this.configuration.organizationsApiBaseUrl}/permissions/HasUserPermissionForSite/${permissionType}/${siteId}`;
        return this.httpClient.get<boolean>(url);
    }

    hasDetailsPermissionForSite(permissionType: PermissionType, siteId: number): Observable<boolean> {
      const url = `${this.configuration.organizationsApiBaseUrl}/permissions/HasUserDetailsPermissionForSite/${permissionType}/${siteId}`;
      return this.httpClient.get<boolean>(url);
  }

    getUserPermissionsOfType(organizationId: number, permissionType: PermissionType): Observable<ZonePermissionApiModel[]> {
        const url =
            `${this.configuration.organizationsApiBaseUrl}/permissions/GetUserPermissionsOfType/${permissionType}/${organizationId}`;
        return this.httpClient.get<ZonePermissionApiModel[]>(url);
    }

    getUserOrganizationPermissionTypes(organizationId: number): Observable<OrganizationPermissionApiModel[]> {
      const url = `${this.configuration.organizationsApiBaseUrl}/permissions/GetUserOrganizationPermissionTypes/${organizationId}`;
      return this.httpClient.get<OrganizationPermissionApiModel[]>(url);
    }
}
